<template>
  <div class="footer">
    <FooterNav></FooterNav>
    <div>Copyright © 2022 World Transportation Logistic Corp |</div>
  </div>
</template>

<script>
  import FooterNav from './FooterNav'
  export default {
    name: 'FooterIndex',
    components: { FooterNav },
  }
</script>

<style scoped lang="scss">
  $footer_bacc : #3a3a3a;
  .footer {
    padding: 2rem 0;
    background-color: $footer_bacc;
    color: #fff;
    text-align: center;
    margin-top: 10vh;
  }
</style>