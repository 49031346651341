<template>
  <el-menu class="footer-nav" mode="horizontal">
    <el-menu-item class="footer-nav-item" index="1" @click="goHome">Home</el-menu-item>
    <el-menu-item class="footer-nav-item" index="2" @click="goContainerTracking">Container
      Tracking</el-menu-item>
    <el-menu-item class="footer-nav-item" index="3" @click="goAbout">About Us</el-menu-item>
    <el-menu-item class="footer-nav-item" index="4" @click="goContactUs">Contact</el-menu-item>
    <el-menu-item class="footer-nav-item" index="5" @click="goService">Service</el-menu-item>
  </el-menu>
</template>

<script>
import { nav_mixin } from '@/mixin'
export default {
  name: 'FooterNav',
  data () {
    return {
      activeIndex: '1',
    }
  },
  mixins: [nav_mixin],
  updated () {
    //this.$bus.$emit('changeNav', this.activeIndex)
  },
}
</script>

<style scoped lang="scss">
$footer_bacc: #3a3a3a;

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.footer-nav {
  display: flex;
  background-color: $footer_bacc;
  align-items: center;
  /* 垂直居中 */
  justify-content: center;
  /* 水平居中 */

  .footer-nav-item {
    color: #fff;
    float: none;
    user-select: none;
  }

  .footer-nav-item:hover {
    background-color: $footer_bacc;
    color: #fff;
  }

  .is-active,
  .is-active:active {
    border-bottom: none !important;
  }

  .is-active:not(.is-disabled):focus,
  .is-active:not(.is-disabled):hover {
    color: #fff;
    background-color: $footer_bacc;
  }

  .el-menu--horizontal>.el-menu-item {
    border-bottom: unset;
  }
}
</style>
