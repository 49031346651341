import Vue from 'vue'

// 重置浏览器css样式
import '@/assets/css/reset.css'
// 自适应rem布局
import '@/assets/js/setrem.js'

//jquery
import jquery from 'jquery'

//引入路由管理
import router from '@/router'
//引入vuex仓库
import store from '@/store'
//引入element ui，按需引入需要配置babel.config.js
import elementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import locale from 'element-ui/lib/locale/lang/en' // lang i18n

//引入登录滑动验证码
import SlideVerify from 'vue-monoplasty-slide-verify'

//引入lodash js工具库
import _ from 'lodash'
//引入swiper样式
import 'swiper/css/swiper.min.css'
//引入全局插件
import GlobalPlugins from '@/plugins/global-plugins.js'

//引入bootstrap 5
import '@/assets/css/bootstrap.min.css'
//引入全局css
import '@/assets/css/commons.css'
//引入全局element ui css
import '@/assets/css/element-ui-custom.css'
// 引入字体样式
import '@/assets/font/font.css'
//awesome 图标库
import 'font-awesome/css/font-awesome.min.css'
//awesome 图标库
import 'animate.css'

//网站入口组件
import App from './App.vue'

//引入google组件
/* import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCp24Ow92vI6lgoik60we4c3WWm7o7ixNs', //需要替换
    libraries: 'places',
  },
}) */

Vue.prototype.$ = jquery
Vue.use(store)
Vue.use(elementUI, { locale })
Vue.use(GlobalPlugins)
Vue.use(SlideVerify)

//关闭开发者提示
Vue.config.productionTip = false

//声明vm，vm的原形对象 Vue.prototype上的所有方法、属性都会在vm、vc实例对象上提现
new Vue({
  el: '#app',
  beforeCreate() {
    //全局事件总线
    Vue.prototype.$bus = this
    //全局工具类
    Vue.prototype._ = _
    //服务器路径,用于图片url
    Vue.prototype.host_url =
      process.env.NODE_ENV === 'production'
        ? process.env.VUE_APP_SERVE
        : process.env.VUE_APP_SERVE + process.env.VUE_APP_SERVER_NAME
  },
  //注册路由（简写： 当对象名和对象参数一致时可简写为单对象名）
  router,
  //注册vuex
  store,
  //解析、渲染模板，(函数式简写，原因为使用了去除解析模块的vue.js，需要调用render函数渲染模板)
  render: h => h(App),
  /* render: function(createElement){
    return createElement(App)
  }, */
  /* render: (h) => {
    return h(App)
  } */
})
