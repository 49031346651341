<!-- 全局入口组件 -->
<template>
  <div id="app">
    <!-- element 回到顶部 -->
    <el-backtop :visibility-height="100" :bottom="100"> </el-backtop>

    <!-- 3·使用组件 -->
    <!-- element layout -->
    <el-container>
      <el-header height="140px" class="pt-3"
        :class="(isDashboardLayout ? 'elHeader-no-fixed' : (isScrollOffset ? 'elHeader' : 'elHeader-normal'))">
        <HomeHeaderTop></HomeHeaderTop>
      </el-header>
      <el-main class="home-main p-0">
        <el-skeleton style="width: 100%" direction="vertical" alignment="flex-start" :loading="loading" animated
          class="content-skeleton">
          <template #template>
            <div class="avatar" style="display: flex; align-items: center;">
              <el-skeleton-item variant="circle"
                style="margin-bottom: 12px; width: 48px; height: 48px; background-color: #aaa;" />
              <div style="
                display: flex; 
                flex-direction: column; 
                position: relative; 
                bottom: 6npmpx;
                margin-left: 8px;">
                <el-skeleton-item variant="text" style="width: 80px; margin-bottom: 8px;" />
                <el-skeleton-item variant="text" style="width: 50px;" />
              </div>
            </div>
            <el-skeleton-item variant="image"
              style="width: 100%; height: 240px; background-color: #aaa;margin-top:15px" />
            <el-skeleton-item variant="image"
              style="width: 100%; height: 240px; background-color: #aaa;margin-top:15px" />
            <div style="padding: 12px 14px 0 0">
              <el-skeleton-item variant="h1" style="width: 50%;" />
              <el-skeleton-item variant="text" style="margin-top: 8px; margin-right: 16px" />
            </div>
          </template>
        </el-skeleton>
        <!-- 路由展示区 -->
        <router-view v-show="!loading"></router-view>
      </el-main>
      <el-footer height="unset" class="p-0">
        <FooterIndex></FooterIndex>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
// 1·引入组件
import HomeHeaderTop from '@/components/Header/HeaderTop'
import FooterIndex from '@/components/Footer/FooterIndex'

import { mapState } from 'vuex'
import { isEmpty, isNotEmpty } from '@/utils/index'

export default {
  name: 'App',
  data () {
    return {
      isScrollOffset: false,
      isDashboardLayout: false,
      loading: false,
    }
  },
  // 2·注册组件
  components: {
    HomeHeaderTop,
    FooterIndex,
  },
  computed: {
    ...mapState({
      pageInfo: (state) => state.web_page.pageInfo,
    }),
  },
  mounted () {
    // 监听页面滚动事件
    window.addEventListener('scroll', this.scrolling)
  },
  watch: {
    $route (route) {
      this.isScrollOffset = true
      /* if (route.name == 'service' || route.name == 'contactUs') {
        this.isScrollOffset = true
      } else {
        this.isScrollOffset = false
      } */

      if (route.path.indexOf('/member/') != -1) {
        this.isScrollOffset = true
        this.isDashboardLayout = true
      } else {
        this.isDashboardLayout = false
      }
    },
    pageInfo: {
      handler (n_val) {
        if (isNotEmpty(n_val)) {
          this.loading = false
        } else {
          if (this.$route.meta.pageLoading == false) {
            this.loading = false
          } else {
            this.loading = true
          }
        }
      },
    },
  },
  methods: {
    scrolling () {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop

      const route = this.$route
      /* if (scrollTop > 60 || (route.name == 'service' || route.name == 'contactUs')) {
        this.isScrollOffset = true
      } else {
        this.isScrollOffset = false
      } */

      if (route.path.indexOf('/member/') != -1) {
        this.isScrollOffset = true
        this.isDashboardLayout = true
      }
    },
  },
}
</script>

<style scope lang="scss">
.el-card__body,
.el-main {
  padding: 0 20px;
}

.elHeader {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.49);
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}

.elHeader-normal {
  position: fixed;
  width: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0);
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}

.elHeader-no-fixed {
  width: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.49);
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}

.home-main {
  min-height: 80vh;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>