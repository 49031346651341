const getters = {
	memberName: (state) => {
		return state.member.memberName
	},
	memberId: (state) => {
		return state.member.memberId
	},
	memberInfo: (state) => {
		return state.member.memberInfo
	},
};
export default getters;
