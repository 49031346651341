import { login, logout, getMemberInfo, getMyorders } from '@/api/member'
import {
  getToken,
  setToken,
  removeToken,
  getRemToken,
  setRemToken,
  removeRemToken,
} from '@/utils/auth'
import { resetRouter } from '@/router'
import { isEmpty, isNotEmpty, showMessageTips } from '@/utils/index'

const getDefaultState = () => {
  return {
    token: getToken(),
    memberName: '',
    memberId: '',
    memberInfo: {},
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: state => {
    Object.assign(state, getDefaultState())
  },
  SET_MEMBERINFO: (state, memberInfo) => {
    state.memberInfo = memberInfo
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_MEMBERNAME: (state, memberName) => {
    state.memberName = memberName
  },
  SET_MEMBERID: (state, memberId) => {
    state.memberId = memberId
  },
}

const actions = {
  // user login
  async login({ commit }, userForm) {
    const { username, password, verifyCode, remember_me } = userForm

    let remToken = ''
    if (remember_me) {
      remToken = getRemToken()
      if (isNotEmpty(remToken)) {
        let rems = remToken.split(';')
        remToken = rems[0]
      }
    } else {
      removeRemToken()
    }

    //登录请求
    const response = await login({
      username: username.trim(),
      password: password,
      verifyCode: verifyCode,
      remFlag: remember_me,
      remToken: remToken,
    })
    const { token } = response
    const memberInfo = response.data

    let remember_token = memberInfo.remember_token
    if (remember_me && isNotEmpty(remember_token)) {
      remember_token = remember_token + ';' + username
      setRemToken(remember_token)
    }
    //登录成功
    commit('SET_TOKEN', token)
    setToken(token)
  },
  //获取登录用户信息
  async getMemberInfo({ commit, state }) {
    const response = await getMemberInfo({ token: state.token })
    const memberInfo = response.data

    commit('SET_MEMBERINFO', memberInfo)
    const { login_user, id } = memberInfo
    commit('SET_MEMBERNAME', login_user)
    commit('SET_MEMBERID', id)
  },
  // user logout
  async logout({ commit, state }) {
    await logout(state.token)

    removeToken() // must remove  token  first
    resetRouter()
    commit('RESET_STATE')
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
