import reqeusts from './request'

export const getCatchCode = params =>
    reqeusts.request({
        url: '/member/getVerifyCode',
        method: 'get',
        params
    })
export const login = params =>
    reqeusts.request({
        url: '/member/login',
        method: 'post',
        data: params,
    })
export const getMemberInfo = params =>
    reqeusts.request({
        url: '/member/getMemberInfo',
        method: 'post',
        data: params,
    })
export const logout = token =>
    reqeusts.request({
        url: '/member/loginOut',
        method: 'get',
        params: {
            token,
        },
    })
export const changePassword = params =>
    reqeusts.request({
        url: '/member/changePassword',
        method: 'post',
        data: params,
    })
export const getMyorders = params =>
    reqeusts.request({
        url: '/member/getOrders',
        method: 'post',
        data: params,
    })
export const getMyorderDetail = params =>
    reqeusts.request({
        url: '/member/getOrderDetail',
        method: 'post',
        data: params,
    })
export const getMydispatchs = params =>
    reqeusts.request({
        url: '/member/getDispatchs',
        method: 'post',
        data: params,
    })
export const getMyTransports = params =>
    reqeusts.request({
        url: '/member/getTransports',
        method: 'post',
        data: params,
    })
