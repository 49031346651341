//路由管理器配置文件，默认配置文件名：index.js
// 引入Vue
import Vue from 'vue'
// 引入路由器
import VueRouter from 'vue-router'
import store from "@/store";
import { getToken } from '@/utils/auth' // get token from cookie
import { MessageBox, Message } from 'element-ui'

// 重写编程式路由跳转方法，push 、 replace
const orignPush = VueRouter.prototype.push
const orignReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    orignPush.call(this, location, resolve, reject)
  } else {
    orignPush.call(
      this,
      location,
      () => { },
      () => { }
    )
  }
}

VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    orignReplace.call(this, location, resolve, reject)
  } else {
    orignReplace.call(
      this,
      location,
      () => { },
      () => { }
    )
  }
}

// 使用路由器插件
Vue.use(VueRouter)
//引入路由配置
import routes from './routes.js'
// 创建路由器对象
const router = new VueRouter({
  mode: process.env.NODE_ENV === 'production' ? 'hash' : 'hash',
  routes,
  //滚动行为
  scrollBehavior() {
    return {
      y: 0,
    }
  },
})
//路由器前置守卫，应用与权限管理
router.beforeEach(async (to, from, next) => {
  const to_path = to.path
  const hasToken = getToken()

  if (to_path === '/login') {
    if (hasToken) {
      next(`/member/dashboard`)
    } else {
      next()
    }
  } else if (to_path.indexOf('/member/') != -1) {
    //member 登录拦截
    if (hasToken) {
      //登录标记通过
      const hasMemberInfo = store.getters.memberName;
      if (hasMemberInfo) {
        next();
      } else {
        try {
          // get user info
          await store.dispatch("member/getMemberInfo");

          next();
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch("member/resetToken");
          next(`/login?redirect=${to.path}`);
        }
      }
    } else {
      //登录未通过 跳转登录页面
      next(`/login?redirect=${to_path}`)
    }
  } else {
    next()
  }
})
// 路由后置守卫，应用与路由鉴权成功后续操作
router.afterEach((to, from) => { })

const createRouter = () =>
  new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: routes,
  })

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

// 暴露router对象
export default router
