import { getPageInfo } from '@/api'

//仓库数据
const state = {
  pageInfo: {},
  navActiveIndex: '1',
}
//唯一修改仓库数据
const mutations = {
  CREATE_PAGE_INFO(state, pageInfo) {
    state.pageInfo = pageInfo
  },
  CLEAR_PAGE_INFO(state) {
    state.pageInfo = {}
  },
}
//处理action，业务逻辑，处理异步
const actions = {
  //此处不能修改仓库数据，只能书写业务逻辑
  async getPageInfo({ commit }, params) {
    let result = await getPageInfo(params)
    if (result.code == 200) {
      let data = result.data
      commit('CREATE_PAGE_INFO', data)
    }
  },
  clearePageInfo({ commit }) {
    commit('CLEAR_PAGE_INFO')
  },
}
//理解为计算属性，简化仓库数据获取，比如 state.b.c.d.e   =>    _getter().e
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
