<!-- 顶部search组件 -->
<template>
  <div>
    <el-input placeholder="Search.." v-model="keyword" class="input-with-select">
      <el-button slot="append" icon="el-icon-search" @click="searchContainerTracking">
      </el-button>
    </el-input>
  </div>
</template>

<script>
  export default {
    name: 'HeaderSearch',
    data() {
      return {
        keyword: '',
      }
    },
    methods: {
      searchContainerTracking() {
        //路由跳转两种方式，声明式导航、编程式导航
        //参数传递方式:1.query,   /url?k=v&k=v
        //           2.params，  需要路由配置中使用占位符    /url/v/

        // 参数传递两种信息
        // 第一种，字符串形式
        //this.$router.push('/container-tracking?keyword='+this.keyword)
        // this.$router.push('/container-tracking/'+this.keyword)

        // 第二种，对象形式
        this.$router.push({
          name: 'containerTracking',
          // query:{
          // 	keyword: this.keyword
          // },
          params: {
            keyword: this.keyword,
          },
        })
        this.keyword = ''
        this.$bus.$emit('activeNav', '2')
      },
    },
  }
</script>

<style scoped>
</style>
