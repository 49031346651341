const nav_mixin = {
    methods: {
        goHome() {
            this.$router.push({
                name: 'homeMain',
            })
        },
        goContainerTracking() {
            this.$router.push({
                name: 'containerTracking',
            })
        },
        goAbout() {
            this.$router.push({
                name: 'about',
            })
        },
        goContactUs() {
            this.$router.push({
                name: 'contactUs',
            })
        },
        goService() {
            this.$router.push({
                name: 'service',
            })
        },
    },
}

export {
    nav_mixin
}
