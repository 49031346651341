import axios from 'axios'
//引入进度条
import nProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store'
import QS from 'qs'
import { getToken } from '@/utils/auth'
import { MessageBox, Message } from 'element-ui'

nProgress.configure({ showSpinner: false })

const reqeusts = axios.create({
  //配置对象
  //url 基础路径
  baseURL: process.env.VUE_APP_BASE_API,
  //请求超时
  timeout: 10000,
  withCredentials: true,
  crossDomain: true,
})

// 请求拦截器
reqeusts.interceptors.request.use(
  config => {
    config.data = QS.stringify(config.data)
    if (getToken()) {
      config.headers['XF-Token'] = getToken()
    }
    nProgress.start()
    // config配置对象，包含header请求头
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// 响应拦截器
reqeusts.interceptors.response.use(
  response => {
    const res = response.data
    nProgress.done()

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== '20000') {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (
        res.code === '50008' ||
        res.code === '50012' ||
        res.code === '50014'
      ) {
        // to re-login
        MessageBox.confirm(
          'You have been logged out, you can cancel to stay on this page, or log in again',
          'Confirm logout',
          {
            confirmButtonText: 'Re-Login',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }
        ).then(() => {
          store.dispatch('member/resetToken').then(() => {
            location.reload()
          })
        })
      } else {
        // error message dialog
        Message({
          message: res.message || 'Error',
          type: 'error',
          duration: 5 * 1000,
        })
      }

      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return {
        data: res.data,
        code: response.status,
        token: res.token,
        message: res.message,
      }
    }
  },
  error => {
    // 响应失败的回调函数
    return Promise.reject(new Error('failed') || 'Error')
  }
)

export default reqeusts
