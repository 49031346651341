<!-- contact us路由组件 -->
<template>
  <el-container style="min-height: 50vh;" class="pt-2">
    <el-container>
      <el-aside class="left-nav">
        <leftNav></leftNav>
      </el-aside>
      <el-main class="child-el-main">
        <breadcrumb class="breadcrumb-container breadcrumb-box" />
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { isEmpty, isNotEmpty } from '@/utils/index'
import leftNav from './leftNav.vue'
import breadcrumb from '@/components/Breadcrumb'

export default {
  name: 'DashboardLayout',
  components: {
    leftNav,
    breadcrumb,
  },
  computed: {},
  data () {
    return {}
  },
  mounted () { },
}
</script>

<style scoped lang="scss">
.left-nav {
  width: 250px !important;
}

.breadcrumb-box {
  line-height: unset !important;
}

.child-el-main {
  padding: 0;
  padding-left: 0.625rem;
}
</style>
