import moment from 'moment'
import { isEmpty, isNotEmpty } from '@/utils/index'

// 自定义插件
export default {
  //插件必须实现install方法，Vue自动调用，参数为Vue原形对象
  install(Vue) {
    //全局字符串切分过滤器
    Vue.filter('filter_subString', function (value) {
      return value.substring(0, 5)
    })

    //全局日期字符串格式化
    Vue.filter('datefmt', function (input, fmtstring) {
      if (isEmpty(input)) {
        return input
      } else {
        return moment(input, 'YYYY/MM/DD HH:mm:ss').format(fmtstring)
      }
    })

    //全局Boolean字符串格式化
    Vue.filter('booleanfmt', function (input) {
      if (input === '' || typeof input === 'undefine') {
        return input
      } else {
        return Boolean(input) ? 'YES' : 'NO'
      }
    })

    //全局Boolean字符串格式化
    Vue.filter('emptyfmt', function (input) {
      if (isEmpty(input)) {
        return '/'
      } else {
        return input
      }
    })
  },
}
