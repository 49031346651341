<!-- 顶部导航组件 -->
<template>
  <el-col :span="24" class="flex-row-reverse">
    <el-menu :mode="menu_model" :collapse="menu_collapse" class="menu-nav d-flex justify-content-between">
      <el-menu-item class="menu-nav-item" index="1" @click="goHome">Home</el-menu-item>
      <el-menu-item class="menu-nav-item" index="2" @click="goContainerTracking">Container Tracking</el-menu-item>
      <el-menu-item class="menu-nav-item" index="3" @click="goAbout">About Us</el-menu-item>
      <el-menu-item class="menu-nav-item" index="4" @click="goContactUs">Contact</el-menu-item>
      <el-menu-item class="menu-nav-item" index="5" @click="goService">Service</el-menu-item>
      <el-menu-item class="menu-nav-item" index="55" @click="goLogin" v-if="is_login">Sign in
      </el-menu-item>
      <el-menu-item class="menu-nav-item" index="66" v-else>
        <el-dropdown>
          <span class="el-dropdown-link account-span">
            Account<i class="el-icon-arrow-down el-icon--right account-span"></i>
          </span>
          <el-dropdown-menu slot="dropdown" class="account-dropdown">
            <el-dropdown-item>
              <span @click="goShipmentInfo">Shipment Info</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="goLoginOut">Sign Out</span></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-menu-item>
    </el-menu>
  </el-col>
</template>

<script>
import HeaderSearch from "./HeaderSearch";
import { nav_mixin } from "@/mixin";
import { mapState } from "vuex";
import { isEmpty, isNotEmpty } from "@/utils/index";

import { getToken } from "@/utils/auth";

export default {
  name: "HomeHeaderNav",
  components: {
    HeaderSearch,
  },
  data () {
    return {
      activeIndex: "",
      menu_model: "horizontal",
      menu_collapse: false,
      clientWidth: 0,
      login_token: "",

      is_login: false,
    };
  },
  mounted () {
    const token = getToken();
    this.is_login = isEmpty(token);

    // 监听屏幕大小
    var clientWidth = document.documentElement.clientWidth;
    this.clientWidth = clientWidth
    this.setNavMenu()

    window.addEventListener('resize', (e) => {
      var clientWidth = document.documentElement.clientWidth;
      this.clientWidth = clientWidth
      this.setNavMenu()
    })
  },
  mixins: [nav_mixin],
  computed: {},
  watch: {
    $route (route) {
      this.activeIndex = "";

      this.$store.dispatch("web_page/clearePageInfo");

      const token = getToken();
      this.is_login = isEmpty(token);
    },
  },
  methods: {
    goLogin () {
      this.$router.push({
        name: "login",
      });

      this.activeIndex = "";
    },
    goShipmentInfo () {
      this.$router.push({
        name: "orders",
      });
    },
    goLoginOut () {
      this.$store.dispatch("member/logout").then(() => {
        this.$router.push({
          name: "login",
        });
      });
    },
    setNavMenu () {
      if (this.clientWidth <= 960) {
        this.menu_model = "vertical"
        this.menu_collapse = true
      }
    }
  },
  beforeDestroy () { },
};
</script>

<style scoped lang="scss">
.el-menu {
  background-color: unset;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: unset !important;
}

.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal>.el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal>.el-submenu .el-submenu__title:hover,
.account-submenu:hover {
  color: #409EFF;
  background-color: unset !important;
}

.account-dropdown {
  z-index: 9999 !important
}

.el-menu--horizontal>.el-menu-item.is-active,
.el-menu--horizontal>.el-menu-item,
.account-span {
  color: #fff;
}

.el-menu.el-menu--horizontal {
  border: none;
}

.menu-nav {
  justify-content: flex-end;

  .menu-nav-item {
    user-select: none;
    font-size: 16px;

    @media screen and (max-width: 500px) {
      padding: 0 10px;
    }

    @media screen and (max-width: 400px) {
      padding: 0 5px;
    }
  }
}
</style>
