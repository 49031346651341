import Cookies from 'js-cookie'

const TokenKey = 'wtranst_user_token'
const RememberMeTokenKey = 'wtranst_user_rememberme_token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getRemToken() {
  const remToken = RememberMeTokenKey
  return Cookies.get(remToken)
}

export function setRemToken(token) {
  const remToken = RememberMeTokenKey
  return Cookies.set(remToken, token)
}

export function removeRemToken() {
  const remToken = RememberMeTokenKey
  return Cookies.remove(remToken)
}
