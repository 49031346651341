import Vue from 'vue'
import Vuex from 'vuex'
import getters from "./getters";

Vue.use(Vuex)

import web_page from './modules/web_page'
import member from './modules/member'
export default new Vuex.Store({
  //实现vuex模块式存储
  modules: {
    member,
    web_page,
  },
	getters,
})
