<!-- contact us路由组件 -->
<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'layoutSingle',
		computed: {},
		data() {
			return {

			}
		},
		mounted() {},
	}
</script>

<style scoped lang="scss">
</style>
