//定义路由器配置对象
//路由配置，name、path、component、meta。。。
//引入需要配置路由的路由组件(懒加载)，@： 配置的src别名
import Layout from '@/pages/Dashboard/layout'
import LayoutSingle from '@/pages/Dashboard/layoutSingle'

export default [
  /* 前台路由 */
  {
    path: '*',
    redirect: '/home',
  },
  {
    //路由名称, 编程式路由跳转使用
    name: 'homeMain',
    // 路由路径
    path: '/home',
    // 路由组件
    component: () => import('@/pages/Home/HomePage/HomeIndex'),
    // 路由特殊持有参数
    meta: {
      activeIndex: '1',
    },
  },
  {
    name: 'containerTracking',
    //path: '/container-tracking/:keyword',
    path: '/container-tracking',
    component: () => import('@/pages/Home/ContainerTracking/ContainerTracking'),
    meta: {
      activeIndex: '2',
    },
  },
  {
    name: 'about',
    path: '/about',
    component: () => import('@/pages/Home/About/About'),
    meta: {
      activeIndex: '3',
    },
  },
  {
    name: 'contactUs',
    path: '/contactus',
    component: () => import('@/pages/Home/ContactUs/ContactUs'),
    meta: {
      activeIndex: '4',
    },
  },
  {
    name: 'service',
    path: '/services',
    component: () => import('@/pages/Home/Service/Service'),
    meta: {
      activeIndex: '5',
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/pages/Home/Login.vue'),
    meta: {
      pageLoading: false,
      activeIndex: '0',
    },
  },
  {
    path: '/member',
    name: 'member',
    component: Layout,
    redirect: '/dashboard',
    meta: {
      is_member_page: true,
    },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('@/pages/Dashboard/dashboard'),
        meta: {
          pageLoading: false,
          title: 'Dashboard',
          icon: 'el-icon-menu',
          index: '1',
          hasChild: false,
        },
      },
      {
        name: 'shipmentInfo',
        path: 'shipment_info',
        component: LayoutSingle,
        redirect: '/orders',
        meta: {
          index: '2',
          title: 'Shipment Info',
          icon: 'el-icon-location',
          hasChild: true,
          redirect: 'noRedirect',
        },
        children: [
          {
            name: 'orders',
            path: 'orders',
            component: () => import('@/pages/Dashboard/orderEntry'),
            meta: {
              pageLoading: false,
              title: 'Order Entry',
              index: '2-1',
            },
          },
          {
            name: 'dispatchs',
            path: 'dispatchs',
            component: () => import('@/pages/Dashboard/dispatch'),
            meta: {
              pageLoading: false,
              title: 'Dispatch',
              index: '2-2',
            },
          },
          {
            name: 'transport',
            path: 'transport',
            component: () => import('@/pages/Dashboard/transport'),
            meta: {
              pageLoading: false,
              title: 'Transport',
              index: '2-3',
            },
          },
        ],
      },
      {
        name: 'profileInfo',
        path: 'profileInfo',
        component: LayoutSingle,
        redirect: '/changePassword',
        meta: {
          index: '3',
          title: 'Profile Info',
          icon: 'el-icon-s-custom',
          hasChild: true,
          redirect: 'noRedirect',
        },
        children: [
          {
            name: 'changePassword',
            path: 'changePassword',
            component: () => import('@/pages/Dashboard/profile/changePassword'),
            meta: {
              pageLoading: false,
              title: 'Change Password',
              index: '3-1',
            },
          },
        ],
      },
    ],
  },
]
