<!-- 前台顶部组件 -->
<template>
  <el-row class="d-flex justify-content-between flex-wrap header-top">
    <div class="d-flex container text-center">
      <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
        <el-image class="cursor-pointer" :src="require('@/assets/wt-logo.png')" :fit="fit"
                  @click="goHome"></el-image>
      </el-col>
      <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14" class="d-flex align-items-center">
        <HeaderNav></HeaderNav>
      </el-col>
    </div>
  </el-row>
</template>

<script>
  import HeaderNav from './HeaderNav'
  import HeaderSearch from './HeaderSearch'

  export default {
    name: 'HeaderTop',
    components: {
      HeaderNav,
      HeaderSearch,
    },
    data() {
      return {
        fit: 'contain',
      }
    },
    methods: {
      goHome: function () {
        this.$router.push({
          name: 'homeMain',
        })

        this.$bus.$emit('activeNav', '1')
      },
    },
  }
</script>

<style scoped lang="scss">
  .header-top {
    justify-content: flex-end;
    align-items: center;
  }
</style>
