<template>
  <el-menu :default-active="menu_activeIndex" class="el-menu-vertical-demo dashbo-left-menu">
    <el-menu-item v-for="(route,index) in routes.children" :key="index"
                  :index="route.meta.index" @click="changeRouter(route.name)"
                  v-if="route.meta.hasChild == false">
      <i :class="route.meta.icon"></i>
      <span>{{route.meta.title}}</span>
    </el-menu-item>

    <el-submenu :index="route.meta.index" v-else>
      <template slot="title">
        <i :class="route.meta.icon"></i>
        {{route.meta.title}}</template>
      <el-menu-item :index="route_child.meta.index" @click="changeRouter(route_child.name)"
                    v-for="(route_child,index_child) in route.children">
        {{route_child.meta.title}}
      </el-menu-item>
    </el-submenu>

    <el-menu-item  @click="goLoginOut">
      <i class="el-icon-switch-button"></i>
      <span>Login Out</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
  export default {
    name: 'leftNave',
    data() {
      return {
        menu_activeIndex: '',
      }
    },
    computed: {
      routes() {
        let routes = this.$router.options.routes

        routes = routes.filter((item) => {
          return item.meta && item.meta.is_member_page == true
        })
        return routes[0] || []
      },
    },
    mounted() {
      this.menu_activeIndex = this.$route.meta.index || ''
    },
    methods: {
      changeRouter(route_name) {
        this.$router.push({
          name: route_name,
        })
      },
      goLoginOut() {
        this.$store.dispatch('member/logout').then(() => {
          this.$router.push({
            name: 'login',
          })
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .dashbo-left-menu {
    height: 100%;
  }
  /deep/ .el-menu-item.is-active {
    color: #fff;
    background: #3c82d0;
  }
</style>
