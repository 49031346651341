import reqeusts from './request'

export const getPageInfo = params =>
  reqeusts.request({
    url: '/front/getPageInfo',
    method: 'get',
    params,
  })

export const getContainerInfo = params =>
  reqeusts.request({
    url: '/front/getContainerInfo',
    method: 'post',
    data: params,
  })
